// import { json, type LoaderFunctionArgs } from '@remix-run/cloudflare'
// import { CallToAction } from '~/components/public/CallToAction'
// import { Faqs } from '~/components/public/Faqs'
// import { Hero } from '~/components/public/Hero'
// import { Pricing } from '~/components/public/Pricing'
// import { PrimaryFeatures } from '~/components/public/PrimaryFeatures'
// import { Reviews } from '~/components/public/Reviews'
// import { SecondaryFeatures } from '~/components/public/SecondaryFeatures'
// import { Layout } from '~/components/public/Layout'
// import { useLoaderData } from '@remix-run/react'
// import { /*Alert,*/ alertModel, type AlertModel } from '~/components/Alert'
// import authenticator from '~/services/auth.server'
// import { type User } from '~/services/users.server'

// export let loader = async ({ request, context }: LoaderFunctionArgs) => {
// 	const user = await authenticator(request, context).isAuthenticated(request)
// 	return json({ alert: alertModel(request), user })
// }

export default function Index() {
	// const data: { alert: AlertModel; user: User | null } = useLoaderData()
	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<h1>Site is under construction</h1>
		</div>
		// <Layout>
		// 	{data.alert ? <Alert {...data.alert} /> : null}
		// 	<div>
		// 		user: ({data?.user?.createdOn}, {data?.user?.userId}, {data?.user?.email}, {data?.user?.emailVerified},{' '}
		// 		{data?.user?.name})

		// 	</div>
		// 	<Hero />
		// 	<PrimaryFeatures />
		// 	<SecondaryFeatures />
		// 	<CallToAction />
		// 	<Reviews />
		// 	<Pricing />
		// 	<Faqs />
		// </Layout>
	)
}
